.hero {
  background-image: radial-gradient(circle, yellow, #fe54a1);
  color: #000000;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
}

.hero > .hero-text {
  width: 100vw;
  min-width: 375px;
  max-width: 750px;

  padding: calc(36px + (72 - 36) * ((100vw - 375px) / 375));
  box-sizing: border-box;
  text-shadow: 0 0 calc(40px + (80 - 40) * ((100vw - 375px) / 375)) #ffffff,
    0 0 calc(40px + (80 - 40) * ((100vw - 375px) / 375)) #ffffff;

  z-index: 1;
}

.hero h1 {
  margin: 0;
  font-size: calc(35px + (70 - 35) * ((100vw - 375px) / 375));
}

.hero h1 + h1 {
  margin-left: -0.875em;
}

@media (max-width: 375px) {
  .hero > .hero-text {
    padding: 36px;
    text-shadow: 0 0 40px #ffffff, 0 0 40px #ffffff;
  }

  .hero h1 {
    font-size: 35px;
  }
}

@media (min-width: 750px) {
  .hero > .hero-text {
    padding: 72px;
    text-shadow: 0 0 80px #ffffff, 0 0 80px #ffffff;
  }

  .hero h1 {
    font-size: 70px;
  }
}

.hero .hlist {
  display: block;
  margin-top: calc(12px + (24 - 12) * ((100vw - 375px) / 375));
}

.hero .hlist > li {
  font-size: calc(16px + (32 - 16) * ((100vw - 375px) / 375));
}

.hero .hlist > li + li {
  padding-left: 0.5em;
}

@media (max-width: 375px) {
  .hero .hlist {
    margin-top: 12px;
  }

  .hero .hlist > li {
    font-size: 16px;
  }
}

@media (min-width: 750px) {
  .hero .hlist {
    margin-top: 24px;
  }

  .hero .hlist > li {
    font-size: 32px;
  }
}
