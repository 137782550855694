.hlist.descs {
  margin-top: calc(8px + (16 - 8) * ((100vw - 375px) / 375));
}

@media (max-width: 375px) {
  .hlist.descs {
    margin-top: 8px;
  }
}

@media (min-width: 750px) {
  .hlist.descs {
    margin-top: 16px;
  }
}
